import ApplicationController from "./application_controller";

// Connects to data-controller="tab-contents"
export default class extends ApplicationController {
  static targets = [ 'tab', 'tabContent']
  static values = {
    extraClasses: { type: Array, default: ["!border-tangerine-80"] }
  }

  changeTab = (e) => {
    let tabId = this.tabTarget.dataset.tabId;
    this.tabTargets.forEach((tab) => {
      if (tab === e.target) {
        if(!tab.classList.contains('active')) {
          tab.classList.add('active')
          this.extraClassesValue.forEach((extraClass) => {
            tab.classList.add(extraClass)
          })
        }
        tabId = tab.dataset.tabId
      } else {
        if(tab.classList.contains('active')) {
          tab.classList.remove('active')
          this.extraClassesValue.forEach((extraClass) => {
            tab.classList.remove(extraClass)
          })
        }
      }
    })
    this.tabContentTargets.forEach((tabContent) => {
      if(tabContent.dataset.tabId == tabId) {
        if(tabContent.classList.contains('hidden')) tabContent.classList.remove('hidden')
      } else {
        if(!tabContent.classList.contains('hidden')) tabContent.classList.add('hidden')
      }
    })
  }
}

