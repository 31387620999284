import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = { targetId: String }

  target(event) {
    event.preventDefault()

    const [, , xhr] = event.detail

    if (this.targetIdValue) {
      document.getElementById(this.targetIdValue).innerHTML = xhr.response
    } else {
      this.element.innerHTML = xhr.response
    }
  }
}
